
// Login page is to get email and password from user to validate account and naivgate to dashboard.

import { defineComponent, reactive, toRefs, ref } from "vue";
import FormGenerator from "@/components/forms/form-generator.vue"; // @ is an alias to /src
import ApiResources from "../../rest-resource";
import {
  emailValidator,
  requiredValidator,
  passwordValidator,
} from "../../validators";

export default defineComponent({
  name: "Login",
  components: {
    FormGenerator,
  },
  setup: () => {
    const validatedForm: any = ref({});
    const state = reactive({
      schema: [
        {
          fieldType: "InputField",
          placeholder: "Email*",
          label: "",
          name: "email",
          type: "email",
          value: "",
          class: "form-group mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Email is required" },
            { validator: emailValidator, message: "Please enter valid email" },
          ],
        },

        {
          fieldType: "InputField",
          placeholder: "Password*",
          label: "",
          name: "password",
          type: "password",
          value: "",
          class: "form-group mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Password is required" },
            {
              validator: passwordValidator,
              message:
                "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters",
            },
          ],
        },
      ],
      submitButton: {
        class: "btn submit-btn float-center",
        value: "Submit",
        loading: "Submitting...",
      },
    });

    return { ...toRefs(state), validatedForm };
  },
  methods: {
    async validForm(data: any) {
      const proxy1 = new Proxy(data, {});
      const dataValues = JSON.parse(JSON.stringify(proxy1));
      dataValues.forEach((element: any) => {
        this.validatedForm[element.name] = element.value;
      });
      try {
        let resourceEndPoint = ApiResources.AuthResource.wrap("/login");
        let result = await resourceEndPoint.post(this.validatedForm);
        localStorage.setItem("user", JSON.stringify(result.data)) as any;
        localStorage.setItem("token", result.data.token) as any;
        let genResource = await ApiResources.generateResources(
          result.data.token
        );
        this.displayMessage("Logged in successfully", "white");
        this.callRestForm();
        this.$router.push("/dashboard");
      } catch (err: any) {
        this.callRestForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    callRestForm(reset = true) {
      (this.$refs["generatedform"] as any).resetForm(reset);
    },
  },
});
